// colors
$color-white: #ffffff;
$color-black: #000000;
$color-light-black: #1e2123;
$color-navy: #193a67;
$color-light-blue: #4474b6;
$color-light-blue-2: #43befe;
$color-blue: #4675b4;
$color-blue-2: #3a73b4;
$color-blue-3: #2467a2;
$color-dark-blue: #3b6398;
$color-grey: #f5f6f7;
$color-grey-2: #98a6b1;
$color-grey-3: #868e95;
$color-grey-4: #cbd2d8;
$color-grey-5: #f5f6f8;
$color-grey-6: #cad2d8;
$color-grey-7: #485156;
$color-grey-8: #92999f;
$color-dark-grey: #1d211a;
$color-dark-grey-2: #3e4447;
$color-red: #ff0141;
$color-green: #3fb718;
$color-dark-green: #035f17;
$color-orange: #ff7519;
$color-yellow: #ffb400;
$color-modal-background: rgba(0, 0, 0, 0.6);
// spacing
$space-large: 50px;
$space-big: 40px;
$space-medium: 30px;
$space-small: 15px;
$space-extra-small: 5px;

// fonts
$font-title: 1.875rem;
$font-headline: 1.25rem;
$font-copy: 1rem;
$font-tag: 0.75rem;

// breakpoints

$extra-small: 360px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;

// additional breakpoints

$extra-wide-screen: 1860px;

// Overwrite cosmo theme styles
$primary: $color-blue !default;
$secondary: $color-white !default;

// Theme colors
$light-bg: #ffffff;
$light-text: #000000;

$dark-bg: #1c1c1c;
$dark-text: #f2f2f2;
$grey-bg: #424242;

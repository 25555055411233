@import "variables.scss";

// font mixins

@mixin type-title {
  font-size: $font-title;
  font-weight: bold;
}

@mixin type-headline {
  font-size: $font-headline;
  font-weight: bold;
}

@mixin type-copy {
  font-size: $font-copy;
  font-weight: normal;
}

@mixin type-tag {
  font-size: $font-tag;
  font-weight: bold;
}

@mixin type-error {
  font-size: $font-tag;
  font-weight: normal;
  color: $color-red;
}

// breakpoints

@mixin bp-extra-small {
  @media (min-width: #{$extra-small}) {
    @content;
  }
}

@mixin bp-small {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin bp-extra-large {
  @media (min-width: #{$extra-large}) {
    @content;
  }
}

// additional breakpoints

@mixin bp-extra-wide-screen {
  @media (min-width: #{$extra-wide-screen}) {
    @content;
  }
}

// formating mixins

@mixin flex-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin reset-padding-margin {
  margin: 0;
  padding: 0;
}

// invisible content
// https://webaim.org/techniques/css/invisiblecontent/
@mixin clip {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

// style mixins
@mixin default-link {
  font-weight: bold;
  text-transform: uppercase;
  color: $color-grey-2;

  &:hover {
    text-decoration: underline;
    color: darken($color-grey-2, 5%);
  }
}

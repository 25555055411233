// variables, mixins etc.
@import "variables.scss";
// bootstrap and cosmo theme
@import "./bootstrap/variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./bootstrap/cosmo.scss";
// slick slider
@import "./slickSlider/custom.scss";

// global styles

body {
  font-size: 16px;
  line-height: 1.2;
  color: $color-light-black;
  overflow-x: hidden;

  &.light-theme {
    background-color: $light-bg;

    & span,
    p {
      color: $light-text;
    }
  }

  &.dark-theme {
    background-color: $dark-bg;

    & span,
    p {
      color: $dark-text;
    }
  }
}

p {
  margin-bottom: 15px;
}

img {
  width: 100%;
}

button {
  text-transform: uppercase;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Place footer always at the bottom of the page
.layout-content {
  $main-header-height: 75px;
  padding-top: $main-header-height;
  min-height: calc(100vh - 994px);
  position: relative;

  @media (min-width: 576px) {
    min-height: calc(100vh - 682px);
  }

  @media (min-width: 768px) {
    min-height: calc(100vh - 502px);
  }

  @media (min-width: 992px) {
    min-height: calc(100vh - 378px);
  }
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// Slick slider styles
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  $size: 30px;

  width: $size;
  height: $size;
  z-index: 1;
  border: none;
  cursor: pointer;

  &::before,
  &:after {
    content: "";
    width: 3px;
    height: 14px;
    background: $color-blue;
    position: absolute;
    border-radius: 10px;
    transform: rotate(-45deg);
    opacity: 1;
  }

  &::before {
    right: 14px;
    top: 4px;
  }

  &::after {
    right: 14px;
    top: 12px;
    transform: rotate(45deg);
  }
}

.slick-arrow.slick-prev {
  left: 5px;

  &::before {
    right: 14px;
    top: 4px;
    transform: rotate(45deg);
  }

  &::after {
    right: 14px;
    top: 12px;
    transform: rotate(-45deg);
  }
}

.slick-arrow.slick-next {
  right: 5px;
}

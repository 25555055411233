@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.indicator {
  position: absolute;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  right: -25px;
  top: 0;
  border-radius: 50%;
  background-color: $color-orange;
  width: 18px;
  height: 18px;
  @include type-tag;
  padding: 0px;
  margin: 0px;
}

// Cosmo 4.1.1
// Bootswatch

// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700" !default;
@import url($web-font-path);

// Navbar ======================================================================

// Buttons =====================================================================

.btn {
  padding: 0.315rem 0.75rem;
}

// Typography ==================================================================

body {
  -webkit-font-smoothing: antialiased;
}

// Tables ======================================================================

// Forms =======================================================================

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: $color-blue;
}

.custom-control-label:before {
  margin-top: -2px;
  background: $color-white;
  border: 1px solid #ced4da;
}

.custom-control-label:after {
  margin-top: -2px;
}

select {
  appearance: none;
  background: {
    // TODO: change icon according to the design
    image: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    repeat: no-repeat;
    position: calc(100% - 10px) 50%;
    size: 10px;
  }
}

.form-group {
  position: relative;
  margin-bottom: 40px;
}

// Navs ========================================================================

// Indicators ==================================================================

// Progress bars ===============================================================

.progress {
  @include box-shadow(none);
  .progress-bar {
    font-size: 8px;
    line-height: 8px;
  }
}

// Containers ==================================================================
